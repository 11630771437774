<template>
  <v-container class="m-0 p-0">
    <v-row>
      <v-col>
        <v-tabs height="26" class="mt-0 ms-3 fs-9pt" v-model="tab" background-color="transparent" :color="area.cor">
          <v-tab class="">
            <div class="text-nonecase f-roboto fs-10pt px-0 ms-0 fw-400" style="">
              <i v-i:ic#add-circle#18></i>
              <span class="ms-1">Novo tópico</span>
            </div>
          </v-tab>
          <v-tab class="">
            <div class="text-nonecase f-roboto fs-10pt px-0 ms-0 fw-400" style="">
              <i v-i:ic#settings-backup-restore#18></i>
              <span class="ms-1">Tópicos antigos</span>
            </div>
          </v-tab>
        </v-tabs>
        <v-tabs-items class="mt-1" v-model="tab">
          <v-tab-item>
            <novotopico :uc="uc" :area="area" />
          </v-tab-item>
          <v-tab-item>
            <topicosantigos :uc="uc" :area="area" />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { rdb, snapshotValToArray } from '@/firebasedb.js';

export default {
  name: "adicionarconteudos",
  components: {
    'topicosantigos': () => import('./topicos-antigos.vue'),
    'novotopico': () => import('./novo-topico.vue'),
  },
  props: {
    area: { default: null, type: Object },
    uc: { default: null, type: Object },
  },
  computed: {},
  data(){
    return {
      loading: false,
      refresh: 0,
      tab: null,
    }
  },
  watch: {},
  mounted: function() {
    var self = this;
    //self.loading = true;
    //rdb.ref('').on('value',function(snapshot) {
    //  self.algo = snapshot.val();
    //  console.log("self.algo",self.algo);
    //  self.build();
    //});
  },
  methods:{

    build() {
      var self = this;
    }
  }
}
</script>

<style scoped>
</style>
